<template>
  <div id="sponsors" v-if="partners">
    <div class="event-logo sponsors__platinium" v-if="showPlatinium && filteredPlatiniumPartner">
      <div v-for="partner in filteredPlatiniumPartner" :key="partner.id">
        <img
          class="hidden-sm-and-down event-logo__picture sponsors__platinium--web"
          :src="partner.logoWeb"
        />
        <img
          class="hidden-md-and-up event-logo__picture sponsors__platinium--mobile"
          :src="partner.logoMobile"
        />
      </div>
    </div>

    <div class="carousel hidden-sm-and-down sponsors__others--web">
      <div class="picture__wrapper" v-for="partner in filteredPartners" :key="partner.id">
        <img class="event-logo__picture carousel__picture" :src="partner.logoWeb" />
      </div>
    </div>

    <div class="carousel hidden-md-and-up sponsors__others--mobile">
      <v-carousel cycle height="100%" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item class="carousel" v-for="partner in filteredPartners" :key="partner.id">
          <v-sheet class="sheet">
            <img class="event-logo__picture carousel__picture" :src="partner.logoMobile" />
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import { PartnerType } from '@/models/event/partner-type.model';

export default {
  name: 'Sponsors',
  props: {
    partners: Array,
    showPlatinium: {
      type: Boolean,
      default: false,
    },
    includeOther: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredPartners() {
      return [
        ...this.partners.filter((partner) => partner.type === PartnerType.regular),
        ...(this.includeOther
          ? this.partners.filter((partner) => partner.type === PartnerType.other)
          : []),
      ].sort((a, b) => a.priority > b.priority);
    },
    filteredRegularPartners() {
      return this.partners
        .filter((partner) => partner.type === PartnerType.regular)
        .sort((a, b) => a.priority > b.priority);
    },
    filteredOtherPartners() {
      return this.partners
        .filter((partner) => partner.type === PartnerType.other)
        .sort((a, b) => a.priority > b.priority);
    },
    filteredPlatiniumPartner() {
      return this.partners
        .filter((partner) => partner.type === PartnerType.platinium)
        .sort((a, b) => a.priority > b.priority);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

#sponsors {
  background-color: #272727;
}

.event-logo {
  &__picture {
    width: 100%;
  }
}

.sheet {
  background-color: var(--v-lightBackground-base);
  text-align: center;
}

@include breakpoint(small) {
  .carousel {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0px, rem(250px)));
    align-items: center;
    justify-content: space-around;

    .picture__wrapper {
      flex-grow: 1;
      flex-basis: 22%;
    }

    &__picture {
      max-width: rem(250px);
      max-height: rem(250px);
    }
  }
}

.picture {
  &__wrapper {
    margin: 10px;
  }
}
</style>
